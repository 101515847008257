import { useState, useRef } from "react";
import Button from "../components/Button";

// Images
import closeButton from "../images/close.png";

interface IProps {
  handleClose: () => void;
  videoSrc: string;
  thumbnailSrc: string;
  ctaSrc: string;
  audio: HTMLAudioElement;
  audioFinished: boolean;
  className?: string;
  style?: React.CSSProperties
}

export default function VideoModal({
  handleClose,
  videoSrc,
  thumbnailSrc,
  ctaSrc,
  audio,
  audioFinished,
  className,
  style
}: IProps) {
  const video = useRef<HTMLVideoElement>(null);
  const [hideThumbnail, setHideThumbnail] = useState<boolean>(false);
  const [fullscreenVideo, setFullscreenVideo] = useState(false);

  function closeThumbnail() {
    if (!audio.paused) {
      return;
    } else {
      setHideThumbnail(true);
      video.current?.play();
    }
  }

  function handleContinue() {
    if (fullscreenVideo) {
      setFullscreenVideo(false);
      return;
    } else if (!audio.paused) {
      return;
    } else {
      handleClose();
      video.current?.pause();
    }
  }

  function handleFullscreen() {
    setFullscreenVideo(true);
  }

  return (
    <div
      className={`modal ${className} ${
        fullscreenVideo ? "fullscreen-modal" : ""
      }`}
    >
      <div
        className={`video-modal--container`}
        style={fullscreenVideo ? { maxHeight: "100vh", maxWidth: "100vw", height: "100vh", width: "100vw" } : {}}
      >
        <div style={style} className={`video-modal--calltoaction ${audioFinished ? 'show-element' : ''} ${hideThumbnail && 'hide-element'}`}>
          <img alt="" src={ctaSrc} />
        </div>
        <div className="video-modal--close-container" onClick={handleContinue}>
          <img alt="" src={closeButton} />
        </div>
        <div
          className={`video-modal--thumbnail-container ${
            hideThumbnail ? "hide-element" : "show-element"
          }`}
        >
          <img
            alt="Video thumbnail"
            className={`${hideThumbnail ? "hide-element" : "show-element"}`}
            onClick={closeThumbnail}
            src={thumbnailSrc}
          />
        </div>
        <video ref={video} playsInline controls controlsList="nodownload" onEnded={() => handleContinue()}>
          <source src={videoSrc} />
        </video>
      </div>
      {fullscreenVideo ? null : (
        <Button
          onClick={handleFullscreen}
          text="Tap to expand video to fullscreen"
          className={fullscreenVideo ? "hide-element" : "show-element"}
          style={{
            opacity: hideThumbnail ? 1 : 0,
            pointerEvents: hideThumbnail ? "auto" : "none",
            background: "white",
            color: "green",
            margin: "1%",
            padding: "1% 2%",
          }}
        />
      )}
    </div>
  );
}
