import { useState, useEffect } from "react";
import ThreeCanvas from "../components/ThreeCanvas";

// 360 Videos
import video1 from "../videos/aerial-1-sphere.mp4";
import video2 from "../videos/aerial-2-sphere.mp4";
import video3 from "../videos/timelapse-sphere.mp4";

// Video Modals
import videoModal1 from "../videos/aerial-2.mp4";
import videoModal2 from "../videos/EVRENZO EDetail MoA v2 _UK.mp4";

// Call to Action (CTA) Thumbnails
import cta1 from "../images/BODY-CTA.png"
import cta2 from "../images/HIF-CTA.png"

// Video Thumbnails
import videoThumbnail1 from "../thumbnails/thumbnail1.png";
import videoThumbnail2 from "../thumbnails/thumbnail2.png";

// Audio
import audio1 from "../audio/Welcome.mp3";
import audio2 from "../audio/Aerial-2.mp3";
import audio3 from "../audio/Timelapse.mp3";

// Modals
import VideoModal from "../modals/VideoModal";
import IntroModal from "../modals/IntroModal";
import EndModal from "../modals/EndModal";

export default function Main() {
  const [active360Video, setActive360Video] = useState(video1);
  const [active360VideoIndex, setActive360VideoIndex] = useState(0);
  const [activeModal, setActiveModal] = useState<number | null>(null);
  const [audioFinished, setAudioFinished] = useState<boolean>(false);
  const [showEnd, setShowEnd] = useState<boolean>(false);
  const videos = [video1, video2, video3];

  // 360 Video State
  const [video] = useState(() => {
    const vid = document.createElement("video");
    vid.setAttribute("playsinline", "true");
    vid.crossOrigin = "Anonymous";
    vid.pause();
    return vid;
  });

  // Audio state
  const [audio] = useState(() => {
    const aud = document.createElement("audio");
    aud.src = audio1;
    return aud;
  });

  function startExperience() {
    video.play();
    audio.play();
    setActiveModal(null);
  }

  function next360Video() {
    setActive360VideoIndex(active360VideoIndex + 1);
    setActive360Video(videos[active360VideoIndex + 1]);
    setActiveModal(null);
  }

  function handleEnd() {
    setActiveModal(null);
    setTimeout(() => {
      setShowEnd(true)
    }, 1500)
  }

  useEffect(() => {
    if (active360VideoIndex === 1) {
      audio.src = audio2;
      audio.play();
      audio.onended = () => setAudioFinished(true);
    }
    if (active360VideoIndex === 2) {
      audio.src = audio3;
      audio.play();
      audio.onended = () => setAudioFinished(true);
    }
  }, [active360VideoIndex]);

  // Set 360 video attributes on video change
  useEffect(() => {
    video.src = active360Video;
    video.loop = false;
    video.muted = true;
    if (active360VideoIndex === 0) {
      video.onended = next360Video;
    }
    if (active360VideoIndex === 1) {
      video.onended = () => setActiveModal(2);
    }
    if (active360VideoIndex === 2) {
      video.onended = () => {
        setActiveModal(3);
      };
    }
    video.play();
  }, [active360Video]);

  // Initial modal popup
  useEffect(() => {
    setActiveModal(1);
    video.pause();
  }, []);

    useEffect(() => {
      if (showEnd) {
        setTimeout(() => {
          window.location.reload();
        }, 20000);
      }
    }, [showEnd]);

  // Modals stay rendered to allow fade in and out
  return (
    <>
      <ThreeCanvas video={video} />
      <IntroModal
        className={activeModal === 1 ? "" : "hide-element"}
        handleStart={startExperience}
      />

      <VideoModal
        className={activeModal === 2 ? "show-element" : "hide-element"}
        handleClose={next360Video}
        videoSrc={videoModal1}
        thumbnailSrc={videoThumbnail1}
        ctaSrc={cta1}
        audio={audio}
        audioFinished={audioFinished}
      />

      <VideoModal
        className={activeModal === 3 ? "show-element" : "hide-element"}
        handleClose={handleEnd}
        videoSrc={videoModal2}
        thumbnailSrc={videoThumbnail2}
        ctaSrc={cta2}
        audio={audio}
        audioFinished={audioFinished}
        style={{bottom: "-20%"}}
      />

      <EndModal className={showEnd ? "show-element" : "hide-element"} />
    </>
  );
}
