import Button from "../components/Button";

interface IProps {
  handleStart: () => void;
  className?: string;
}

export default function IntroModal({ handleStart, className }: IProps) {
  return (
    <div className={`modal intro-modal fullscreen-modal ${className}`}>
      <p>
        This information is intended for Healthcare Professionals in the UK.{" "}
        <br /> By proceeding you are confirming that you are a Healthcare
        Professional.
      </p>
      <div className="welcome-container">
        <p>Welcome to the Himalayas - the highest region on Earth</p>
        <p>
          This is an interactive 360° experience <br /> Move your device around to
          explore the mountain scenery
        </p>
      </div>
      <Button onClick={handleStart} text="Start Experience" />
      <div className="adverse-container">
        <p>
          Adverse events should be reported. Reporting forms and information can
          be found at www.mhra.gov.uk/yellowcard or search for MHRA Yellow Card
          in the Google Play or Apple App Store. Adverse events should also be
          reported to Astellas Pharma Ltd. on 0800 783 5018.
        </p>
        <p>
          EVRENZO<sup>TM▼</sup> (roxadustat) is indicated for treatment of adult
          patients with symptomatic anaemia associated with chronic kidney
          disease (CKD).<sup>1</sup>
        </p>
        <p>
          Prescribing information is available on request, via our portal or
          from your local Astellas representative.
        </p>
        <p>1. EVRENZO (roxadustat) Summary of Product Characteristics.</p>
        <p>MAT-UK-EVZ-2023-00162. Date of preparation: May 2023. </p>
      </div>
    </div>
  );
}
