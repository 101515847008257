import "./index.css";
import { useState } from "react";

// Components
import Accelerometer from "./components/Accelerometer";
import Main from "./components/Main";

function App() {
  const [permissionGranted, setPermissionGranted] = useState(false);

  console.log(window.location.href);

  if ((window.location.href === "https://www.everenzo-ukkw-360video.com/") || (window.location.href === "https://www.everenzo-ukkw-360video.com")) {
    window.location.replace("https://www.evrenzo-ukkw-360video.com")
  }

  return (
    <div className="App">
      {permissionGranted ? (
        <Main />
      ) : (
        <Accelerometer
          permissionGranted={permissionGranted}
          setPermissionGranted={setPermissionGranted}
        />
      )}
    </div>
  );
}

export default App;
