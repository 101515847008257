interface IProps {
  text: string;
  onClick: () => void;
  className?: string;
  style?: React.CSSProperties;
}

export default function Button({ text, onClick, className, style }: IProps) {
  return (
    <button onClick={onClick} style={style} className={`${className} button show-element`}>
      <p>{text}</p>
    </button>
  );
}
