import EndImage from "../images/EndImage.png"

interface IProps {
  className: string;
}

export default function EndModal({ className }: IProps) {

  return (
    <div className={`modal end-modal ${className}`}>
      <img src={EndImage}/>
    </div>
  );
}
